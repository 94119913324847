import React, { Component } from 'react'
import CKEditor from 'ckeditor4-react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Button from '../../common/Button/Button'
import { validateTerms } from '../../../validations/cms'
import { addTerms, getCMS } from '../../../actions/dashboardActions'
import './styles.scss'

class Terms extends Component {
  constructor() {
    super()
    this.state = {
      editor: '',
      text: '',
      language: 'en'
    }
  }

  componentDidMount() {
    const token = localStorage.getItem('jwtToken')
    this.props.getCMS(token, this.state.language)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard.cms) {
      if (this.state.language === 'fr') {
        this.setState({ text: nextProps.dashboard.cms.frTerms })
      } else if (this.state.language === 'en') {
        this.setState({ text: nextProps.dashboard.cms.enTerms })
      }
      if (this.state.language === 'it') {
        this.setState({ text: nextProps.dashboard.cms.itTerms })
      }
      if (this.state.language === 'es') {
        this.setState({ text: nextProps.dashboard.cms.esTerms })
      }
      if (this.state.language === 'pl') {
        this.setState({ text: nextProps.dashboard.cms.poTerms })
      }
      if (this.state.language === 'de') {
        this.setState({ text: nextProps.dashboard.cms.grTerms })
      }
    }
  }

  handleInit = (editor) => {
    this.setState({ editor })
  }

  handleChange = (event) => {
    const data = event.editor.getData()
    this.setState({ text: data })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { text, language } = this.state
    const type = 'owner'

    const result = validateTerms(text)
    if (result) return toast.error(result)

    const token = localStorage.getItem('jwtToken')
    this.props.addTerms(text, type, token, language)
  }

  handleLanguageChange = (e) => {
    const token = localStorage.getItem('jwtToken')
    this.setState({ language: e })
    this.props.getCMS(token, e)
  }

  render() {
    return (
      <>
        <div
          className='add-about-wrapper'
        // style={{ marginLeft: this.props.collapsed ? "120px" : "320px" }}
        >
          <h5 className='text-left'>Terms & Conditions</h5>
          <div className='cms_box'>
            <div className='row mx-0'>
              <div className='col-md-12 px-0 about_form'>
                <form onSubmit={this.handleSubmit}>
                  <div className='col-md-4 px-0 mb-3'>
                    <select
                      name='filterAppointmentReport'
                      className='form-control shadow-none'
                      onChange={(e) => this.handleLanguageChange(e.target.value)}
                    >
                      <option value='en'>English</option>
                      <option value='fr'>French</option>
                      <option value='es'>Spanish</option>
                      <option value='de'>German</option>
                      <option value='pl'>Polish</option>
                      <option value='it'>Italian</option>
                    </select>
                  </div>
                  <CKEditor
                    data={this.state.text}
                    onReady={(editor) => this.handleInit(editor)}
                    onChange={(event) =>
                      this.handleChange(event)}
                  />
                  {this.props.accessRole === '0' ?
                    <Button
                      type='submit'
                      className='btn submit-btn shadow-none'
                      value='Update'
                    />
                    :null}
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

Terms.propTypes = {
  dashboard: PropTypes.object.isRequired,
  addTerms: PropTypes.func.isRequired,
  getCMS: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  accessRole: state.dashboard.accessRole,

})

export default connect(mapStateToProps, { addTerms, getCMS })(Terms)
