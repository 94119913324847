export const IS_LOADING = 'IS_LOADING'
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED'
export const ADMIN_PROFILE_DATA = 'ADMIN_PROFILE_DATA'
export const FAQ = 'FAQ'
export const SINGLE_FAQ = 'SINGLE_FAQ'
export const ABOUT = 'ABOUT'
export const HELP = 'HELP'
export const TERM = 'TERM'
export const ALL_REVIEWS = 'ALL_REVIEWS'
export const VERIFY_ERROR = 'VERIFY_ERROR'
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS'
export const GET_QUESTION = 'GET_QUESTION'
export const ALL_CATEGORY = 'ALL_CATEGORY'
export const GET_WALKERS = 'GET_WALKERS'
export const GET_WALKER = 'GET_WALKER'
export const GET_OWNER = 'GET_OWNER'
export const GET_OWNERS = 'GET_OWNERS'
export const GET_CMS = 'GET_CMS'
export const GET_BOOKINGS = 'GET_BOOKINGS'
export const GET_COMMISSION = 'GET_COMMISSION'
export const GET_DELETE_USER = 'GET_DELETE_USER'
export const GET_USER_DETAIL = 'GET_USER_DETAIL'

