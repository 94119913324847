import React from 'react'
import { Modal } from 'react-bootstrap'
import { CSVLink } from 'react-csv'

import Button from '../Button/Button'
import './styles.scss'

const ExportDataAsCSV = (props) => {
  return (
    <>
      <Modal show={props.showModal} onHide={props.handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.text}</Modal.Body>
        <Modal.Footer>
          <CSVLink
            data={props.data}
            onClick={props.handleYesBtnClick}
            filename='user.csv'
          >
            <Button
              value='Yes'
              className='btn btn-danger shadow-none yes-btn'
              type='button'
            />
          </CSVLink>
          <Button
            value='No Thanks'
            className='btn shadow-none no-btn btn-success'
            type='button'
            handleClick={props.handleNoBtnClick}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ExportDataAsCSV
