import React, { Component } from 'react'
import './styles.scss'
import { getCMS, getWalker } from '../../../actions/dashboardActions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'

class WalkerTerms extends Component {
  constructor (props) {
    super(props)
    this.state = {
      text: '',
      language: ''
    }
  }

  componentDidMount () {
    const { id } = queryString.parse(this.props.location.search)
    const token = localStorage.getItem('jwtToken')
    this.props.getCMS(token)
    this.props.getWalker(id, token)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps?.dashboard?.singleWalkerInfo) {
      const lang = nextProps?.dashboard?.singleWalkerInfo?.basicInfo?.language
      this.setState({ language: lang })
      if (lang === 'fr') {
        this.setState({ text: nextProps.dashboard.cms.frWalkerTerms })
      } else if (lang === 'en') {
        this.setState({ text: nextProps.dashboard.cms.enWalkerTerms })
      } else if (lang === 'it') {
        this.setState({ text: nextProps.dashboard.cms.itWalkerTerms })
      } else if (lang === 'es') {
        this.setState({ text: nextProps.dashboard.cms.esWalkerTerms })
      } else if (lang === 'pl') {
        this.setState({ text: nextProps.dashboard.cms.poWalkerTerms })
      } else if (lang === 'de') {
        this.setState({ text: nextProps.dashboard.cms.grWalkerTerms })
      } else {
        this.setState({ text: nextProps.dashboard.cms.enWalkerTerms })
      }
    }
  }

  render () {
    const currentLang = this.state.language || 'en'
    const heading = currentLang === 'es'
      ? 'Términos y condiciones'
      : currentLang === 'fr'
        ? 'Termes et conditions'
        : currentLang === 'pl'
          ? 'Zasady i warunki'
          : currentLang === 'it'
            ? 'Termini e Condizioni'
            : currentLang === 'de'
              ? 'Geschäftsbedingungen'
              : 'Terms & conditions'
    return (
      <div className='container-fluid policy'>
        <div className='row mx-0'>
          <div className='col-md-12 px-0'>
            <h4>{heading}</h4>
            <hr />
          </div>
          <div className='col-md-12 px-0'>
            <div dangerouslySetInnerHTML={{ __html: this.state.text }} />
          </div>
        </div>
      </div>
    )
  }
}
WalkerTerms.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getCMS: PropTypes.func.isRequired,
  getWalker: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard
})

export default connect(mapStateToProps, { getCMS, getWalker })(WalkerTerms)
