import React, { Component } from 'react'
import './styles.scss'
import { getCMS, getOwner, getWalker } from '../../../actions/dashboardActions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'

class PrivacyPolicy extends Component {
  constructor (props) {
    super(props)
    this.state = {
      text: '',
      language: '',
      type: ''
    }
  }

  componentDidMount () {
    const { id, type } = queryString.parse(this.props.location.search)
    this.setState({ type })
    const token = localStorage.getItem('jwtToken')
    this.props.getCMS(token)
    if (type === 'owner') {
      this.props.getOwner(id, token)
    } else {
      this.props.getWalker(id, token)
    }
  }

  componentWillReceiveProps (nextProps) {
    console.log('type==', this.state.type)
    if (this.state.type === 'owner' && nextProps?.dashboard?.singleOwnerInfo) {
      const lang = nextProps?.dashboard?.singleOwnerInfo?.basicInfo?.language
      this.setState({ language: lang })
      if (lang === 'fr') {
        this.setState({ text: nextProps.dashboard.cms.frPolicy })
      } else if (lang === 'en') {
        this.setState({ text: nextProps.dashboard.cms.enPolicy })
      } else if (lang === 'it') {
        this.setState({ text: nextProps.dashboard.cms.itPolicy })
      } else if (lang === 'es') {
        this.setState({ text: nextProps.dashboard.cms.esPolicy })
      } else if (lang === 'po') {
        this.setState({ text: nextProps.dashboard.cms.poPolicy })
      } else if (lang === 'gr') {
        this.setState({ text: nextProps.dashboard.cms.grPolicy })
      } else {
        this.setState({ text: nextProps.dashboard.cms.enPolicy })
      }
    } else {
      if (nextProps?.dashboard?.singleWalkerInfo) {
        const lang = nextProps?.dashboard?.singleWalkerInfo?.basicInfo?.language
        this.setState({ language: lang })

        if (lang === 'fr') {
          this.setState({ text: nextProps.dashboard.cms.frPolicy })
        } else if (lang === 'en') {
          this.setState({ text: nextProps.dashboard.cms.enPolicy })
        } else if (lang === 'it') {
          this.setState({ text: nextProps.dashboard.cms.itPolicy })
        } else if (lang === 'es') {
          this.setState({ text: nextProps.dashboard.cms.esPolicy })
        } else if (lang === 'pl') {
          this.setState({ text: nextProps.dashboard.cms.poPolicy })
        } else if (lang === 'de') {
          this.setState({ text: nextProps.dashboard.cms.grPolicy })
        } else {
          this.setState({ text: nextProps.dashboard.cms.enPolicy })
        }
      }
    }
  }

  render () {
    const currentLang = this.state.language || 'en'
    const heading = currentLang === 'es'
      ? 'Política de privacidad'
      : currentLang === 'fr'
        ? 'Politique de confidentialité'
        : currentLang === 'pl'
          ? 'Polityka prywatności'
          : currentLang === 'it'
            ? 'Informativa sulla privacy'
            : currentLang === 'de'
              ? 'Datenschutz'
              : 'Privacy Policy'

    return (
      <div className='container-fluid policy'>
        <div className='row mx-0'>
          <div className='col-md-12 px-0'>
            <h4>{heading}</h4>
            <hr />
          </div>
          <div className='col-md-12 px-0'>
            <div dangerouslySetInnerHTML={{ __html: this.state.text }} />

          </div>
        </div>
      </div>
    )
  }
}
PrivacyPolicy.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getCMS: PropTypes.func.isRequired,
  getWalker: PropTypes.func.isRequired,
  getOwner: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard
})

export default connect(mapStateToProps, { getCMS, getOwner, getWalker })(PrivacyPolicy)
