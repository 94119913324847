import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { Link } from 'react-router-dom'
import UpdateCommission from '../../common/Modal/UpdateCommission'

import {
  getCommission,
  updateCommission
} from '../../../actions/dashboardActions'
import './styles.scss'

class ManageCommission extends Component {
  constructor () {
    super()
    this.state = {
      id: '',
      pageLimit: 10,
      currentPage: 1,
      query: '',
      planId: '',
      activeBtn: true,
      inactivebtn: false,
      status: 'Active',
      showViewModal: false,
      showUpdateModal: false,
      commissionUpdate: '',
      discountPercentUpdate: '',
      planIdUpdate: '',
      activeBtnUpdate: true,
      inactivebtnUpdate: false,
      statusUpdate: 'Active'
    }
  }

  componentDidMount () {
    const token = localStorage.getItem('jwtToken')

    this.props.getCommission(token)
  }

  componentWillReceiveProps (nextProps) {
    if (this.state.showUpdateModal && nextProps.dashboard.singleCommission) {
      const data = nextProps.dashboard.singleCommission

      this.setState({
        commissionUpdate: data.walkerCommission

      })
    }
  }

  handleSearch = (query) => {
    this.setState({ query })

    const token = localStorage.getItem('jwtToken')
    this.props.searchCoupon(query, token)
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleRadioChange = (status) => {
    if (status === 'Active') {
      this.setState({ status: 'Active', activeBtn: true, inactivebtn: false })
    } else {
      this.setState({
        status: 'Inactive',
        activeBtn: false,
        inactivebtn: true
      })
    }
  }

  handlePlanChange = (e) => {
    this.setState({ planId: e.target.value })
  }

  handleViewModalClose = () => {
    this.setState({ showViewModal: false })
  }

  handleUpdateModalShow = () => {
    this.setState({ showUpdateModal: true })

    const token = localStorage.getItem('jwtToken')
    this.props.getCommission(token)
  }

  handleUpdateModalClose = () => {
    this.setState({ showUpdateModal: false })
    this.setState({
      commissionUpdate: '',
      status: 'Active'
    })
  }

  handleUpdateChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleUpdatePlanChange = (e) => {
    this.setState({ planIdUpdate: e.target.value })
  }

  handleUpdateCommissionSubmit = (e) => {
    e.preventDefault()

    const {
      commissionUpdate
    } = this.state

    const token = localStorage.getItem('jwtToken')
    this.props.updateCommission(commissionUpdate, token)

    this.props.getCommission(token)

    this.setState({
      showUpdateModal: false,
      commissionUpdate: ''
    })
  }

  render () {
    const commission_obj = this.props.dashboard.singleCommission
    return (
      <>
        <div className='manage-coupon-wrapper'>
          <h5>Manage Commission</h5>
          <hr />

          <div className='manage-coupon custom_class'>
            <div className='row mx-0'>
              <div className='col-md-12 px-0'>
                <div className='table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>Commission</th>
                        <th>Date</th>
                        {this.props.accessRole === '0' ?
                        <th>Action</th> : null}
                      </tr>
                    </thead> 
                    <tbody>
                      {commission_obj &&
                        <tr>
                          <td>{commission_obj.walkerCommission}</td>
                          <td>
                            {moment(commission_obj.created_at).format('MMM DD, YYYY')}
                          </td>
                          {this.props.accessRole === '0' ?
                          <td>
                            <Link
                              to='#'
                              onClick={() =>
                                this.handleUpdateModalShow(commission_obj._id)}
                            >
                              <i className='fa fa-edit' />
                            </Link>
                          </td>
                          :null}
                        </tr>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <UpdateCommission
          showUpdateModal={this.state.showUpdateModal}
          handleUpdateModalClose={this.handleUpdateModalClose}
          handleUpdateCommissionSubmit={this.handleUpdateCommissionSubmit}
          commission={this.state.commissionUpdate}
          handleChange={this.handleUpdateChange}

        />
      </>
    )
  }
}

ManageCommission.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getCommission: PropTypes.func.isRequired,
  updateCommission: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  accessRole:state.dashboard.accessRole
})

export default connect(mapStateToProps, {
  getCommission,
  updateCommission
})(ManageCommission)
