import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../Button/Button'
import FormInput from '../Form-Input/FormInput'
import VienRadio from '../Radio/Radio'
import './styles.scss'

const UpdateCommission = (props) => {
  return (
    <>
      <Modal
        show={props.showUpdateModal}
        onHide={props.handleUpdateModalClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Commission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={props.handleUpdateCommissionSubmit}>
            <FormInput
              icon='fa fa-user icon'
              size='15px'
              type='text'
              name='commissionUpdate'
              placeholder='Commission amount'
              value={props.commission}
              handleChange={props.handleChange}
            />

            <Button
              type='submit'
              value='Update Coupon'
              className='btn btn-primary btn-block add-doctor-btn shadow-none'
            />
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UpdateCommission
