import isEmpty from 'is-empty'

export const validateAddOwner = (
  fullName,
  email,
  phoneNumber
) => {
  if (isEmpty(fullName)) return 'Please enter first name'
  if (fullName.length < 3) return 'First name must be atleast 3 characters'
  if (fullName.length > 255) return 'First name must be atmost 255 characters'

  if (isEmpty(email)) return 'Please enter email'

  if (isEmpty(phoneNumber)) return 'Please enter phone number'
  if (isNaN(phoneNumber)) return 'Please enter a valid phone number'
  if (phoneNumber.length !== 10) return 'Invalid phone number'
}

export const validateEditOwner = (
  fullName,
  email,
  phoneNumber,
  dob,
  height,
  weight,
  address,
  country,
  state,
  city,
  pin,
  bloodGroup,
  maritalStatus
) => {
  if (isEmpty(fullName)) return 'Please enter first name'
  if (fullName.length < 3) return 'First name must be atleast 3 characters'
  if (fullName.length > 255) return 'First name must be atmost 255 characters'

  if (isEmpty(email)) return 'Please enter email'

  if (isEmpty(phoneNumber)) return 'Please enter phone number'
  if (isNaN(phoneNumber)) return 'Please enter a valid phone number'
  if (phoneNumber.length !== 10) return 'Invalid phone number'

  if (isEmpty(dob)) return 'Please select dob'

  if (isEmpty(height)) return 'Please enter height'
  if (height < 0) return 'Height should be greather than 0'
  if (height > 300) return 'Height should be greather than 300'

  if (isEmpty(weight)) return 'Please enter weight'
  if (weight < 0) return 'Weight should be greather than 0'
  if (weight > 300) return 'Height should be greather than 300'

  if (isEmpty(address)) return 'Please enter address'

  if (isEmpty(country)) return 'Please enter country'

  if (isEmpty(state)) return 'Please enter state'

  if (isEmpty(city)) return 'Please enter city'

  if (isEmpty(pin)) return 'Please enter pin'
  if (isNaN(pin)) return 'Please enter a valid pin'
  if (pin.length !== 6) return 'Invalid pin'

  if (isEmpty(bloodGroup)) return 'Please enter bloodGroup'
  if (bloodGroup.length > 2) return 'Invalid blood group'

  if (isEmpty(maritalStatus)) return 'Please enter marital status'
}
