

import React, { memo, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getWalker, updateWalker } from '../../../actions/dashboardActions';
import { api_url } from '../../../utils/api';
import DeleteModalFundRequest from '../../common/Modal/DeleteModalFundRequest';
import Pagination from '../../common/Pagination/Pagination';
import Search from '../../common/Search/Search';
import FundTable from './Table';
import './styles.scss';
import { axiosInstance } from '../../../actions/authActions';

const CrisisFund = () => {
    const token = localStorage.getItem('jwtToken');
    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [fundList, setFundList] = useState({
        totalPages: 0, totalRequests: 0, currentPage: 0, data: []
    });
    const searchTimeout = useRef(null);

    const [showDeleteModal, setShowDeleteModal] = React.useState({
        show: false,
        id: ''
    });

    const getTableData = (search) => {
        axiosInstance.get(api_url + `/api/admin/get/crisis-Fund-Request?page=${page}&limit=20&search=${search ? search : ""}`, { headers: { 'x-access-token': token } })
            .then((res) => {
                if (res.data.success) {
                    setFundList(res.data);
                }
            })
            .catch((err) => console.log(err))
    }

    const rejectRequest = () => {
        axiosInstance.put(api_url + `/api/admin/approve-reject-crisis-fund/${showDeleteModal.id}?action=rejected`, showDeleteModal.id, { headers: { 'x-access-token': token } })
            .then((res) => {
                if (res.data.success) {
                    getTableData();
                    setShowDeleteModal({ show: false, id: '' })
                }
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        getTableData();
    }, [page])

    const handleSearch = (e) => {
        setSearchValue(e);
        setPage(1);
        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }
        searchTimeout.current = setTimeout(() => {
            getTableData(e);
        }, 1000);
    }

    const handlePaginationClick = (pageNumber) => {
        console.log(pageNumber, "pageNumber")
        setPage(pageNumber)
    }

    const handleRejectReq = (e) => {
        console.log('Reject', showDeleteModal.id);
        setShowDeleteModal({ show: true, id: e });
    }

    const handleAcceptReq = () => {
        console.log('Accept', showDeleteModal.id);
    }

    const startResult = (page - 1) * 20 + 1;
    const endResult = Math.min(page * 20, fundList.totalRequests);

    return (
        <>

            {showDeleteModal.show && <DeleteModalFundRequest
                showDeleteModal={showDeleteModal.show}
                handleDeleteModalClose={() => setShowDeleteModal({ show: false, id: '' })}
                title='Reject Crisis fund eligibility'
                text={`Are you sure to reject the Crisis fund eligibility for ${fundList.data.find((ele) => ele._id === showDeleteModal.id)?.ownerId?.basicInfo?.fullName}`}
                handleYesBtnClick={rejectRequest}
                handleNoBtnClick={() => setShowDeleteModal({ show: false, id: '' })}
            />}

            <div className='manage-doctor-wrapper manage_walkers_page'>
                <h5 className='text-left'>Crisis Fund</h5>
                <div className='row mx-0'>
                    <div className='col-md-6 pl-0 search_col'>
                        <Search
                            icon='fa fa-search icon'
                            size='15px'
                            placeholder='Search'
                            search={searchValue}
                            handleSearch={handleSearch}
                        />
                    </div>
                </div>
                <div className='manage-doctor custom_class'>
                    <div className='table-responsive'>
                        <FundTable rejectRequest={handleRejectReq} fundList={fundList.data} classname='dashboard_table' />
                    </div>
                </div>
                {fundList?.totalRequests ? <div className='row result_row mx-0'>
                    <div className='col-md-6 px-0 left_col'>
                        <h6 className='results'>
                            Showing {startResult} - {endResult} results of{' '}
                            {fundList?.totalRequests}
                        </h6>
                    </div>
                    <div className='col-md-6 px-0 right_col'>
                        {fundList?.totalRequests ? <Pagination
                            count={fundList?.totalPages}
                            totalItem={fundList?.totalRequests}
                            pageLimit={20}
                            currentPage={fundList?.currentPage}
                            handlePageClick={handlePaginationClick}
                        /> : ""}
                    </div>
                </div> : ""}

            </div>

        </>
    )
}

const mapStateToProps = (state) => ({
    dashboard: state.dashboard,
    accessRole: state.dashboard.accessRole
})

export default connect(mapStateToProps, { getWalker, updateWalker })(
    memo(CrisisFund)
)
